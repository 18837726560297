@keyframes fade-up {
  from {
    opacity: 0;
    transform: translateY(var(--medium));
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }  
}

.reveal-1 {
  opacity: 0;
  animation: fade-up 0.75s ease forwards;
}
.reveal-2 {
  opacity: 0;
  animation: fade-up 0.75s ease forwards 0.1s;
}
.reveal-3 {
  opacity: 0;
  animation: fade-up 0.75s ease forwards 0.2s;
}
.reveal-4 {
  opacity: 0;
  animation: fade-up 0.75s ease forwards 0.3s;
}
.reveal-5 {
  opacity: 0;
  animation: fade-up 0.75s ease forwards 0.4s;
}
.reveal-6 {
  opacity: 0;
  animation: fade-up 0.75s ease forwards 0.5s;
}
.reveal-7 {
  opacity: 0;
  animation: fade-up 0.75s ease forwards 0.6s;
}
.reveal-8 {
  opacity: 0;
  animation: fade-up 0.75s ease forwards 0.7s;
}
